<template>
  <header id="page-topbar" class="nav-bar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-dark">
            <span :class="{ 'logo-sm': true, 'sidebar-open': true }">
              <img src="@/assets/images/logo.png" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-full.png" />
            </span>
          </router-link>

          <router-link tag="a" to="/" class="logo logo-light">
            <span :class="{ 'logo-sm': true, 'sidebar-open': isSidebarOpen }">
              <img src="@/assets/images/logo.png" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" />
            </span>
          </router-link>
        </div>
        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <div class="d-flex align-items-center">
              <img
                class="rounded-circle header-profile-user"
                src="@/assets/images/user.png"
                alt="Header Avatar"
              />
              <span class="d-none d-xl-inline-block ml-1">{{ name }}</span>
            </div>
          </template>
          <b-dropdown-item @click="$store.dispatch('auth/logout')">
            <i
              class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"
            ></i>
            <span class="text-danger">{{ $t("logout") }}</span>
          </b-dropdown-item>
          <b-dropdown-item>
            <span class="text-black-50 font-size-12"
              >Application version: {{ this.version }}</span
            >
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {};
  },
  computed: {
    isSidebarOpen() {
      return this.$store.getters["layout/menu"];
    },
    version() {
      return process.env.VUE_APP_VERSION;
    }
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    }
  }
};
</script>

<style lang="scss">
.nav-bar {
  .logo {
    .logo-lg img {
      width: 200px !important;
      margin-top: 20px;
    }
    .logo-sm {
      img {
        width: 40px;
        margin-top: 1em;
      }
      &.sidebar-open img {
        margin-left: -0.8em;
      }
    }
  }

  &#page-topbar {
    position: fixed;
  }
}
</style>
